<template>
  <div class="grid grid-cols-2 justify-items-center gap-4 mx-8">
    <div class="apple-app-store-header">
      <img
        class="apple-app-store-img"
        src="../../public/assets/apple-app-store-logo.png"
        alt="apple-app-store-logo"
      />
      <span class="apple-app-store-title">Apple App Store</span>
    </div>
    <div class="google-play-store-header">
      <img
        class="google-play-store-img"
        src="../../public/assets/google-play-store-logo.png"
        alt="google-play-store-logo"
      />
      <span class="google-play-store-title">Google Play Store</span>
    </div>
    <!-- OLG - iOS -->
    <div
      class="
        w-10/12
        flex flex-col
        items-center
        bg-white
        rounded-lg
        border
        shadow-md
        md:max-w-2xl md:flex-row
        hover:bg-gray-100
      "
    >
      <img
        class="
          rounded-t-lg
          md:h-auto
          scale-[0.35]
          md:w-40 md:rounded-none md:rounded-l-lg
        "
        src="../../public/assets/OLG-icon.png"
        alt=""
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h6 class="mb-2 font-bold tracking-tight text-orange-600">
          {{ olgiOS.name }}
        </h6>
        <p class="mb-2 font-bold text-gray-700 ">
          Published Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ olgiOS.current_version }} {{ olgiOS.current_version_type }}
        </p>
        <br />
        <p class="mb-2 font-bold text-gray-700 ">
          Pending Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ olgiOS.pending_version }} {{ olgiOS.pending_version_type }}
        </p>
      </div>
    </div>
    <!-- OLG - Android -->
    <div
      class="
        w-10/12
        flex flex-col
        items-center
        bg-white
        rounded-lg
        border
        shadow-md
        md:flex-row md:max-w-2xl
        hover:bg-gray-100
      "
    >
      <img
        class="
          rounded-t-lg
          md:h-auto
          scale-[0.35]
          md:w-40 md:rounded-none md:rounded-l-lg
        "
        src="../../public/assets/OLG-icon.png"
        alt=""
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h6 class="mb-2 font-bold tracking-tight text-orange-600">
          {{ olgAndroid.name }}
        </h6>
        <p class="mb-2 font-bold text-gray-700 ">
          Published Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ olgAndroid.current_version }} {{ olgAndroid.current_version_type }}
        </p>
        <br />
        <p class="mb-2 font-bold text-gray-700 ">
          Pending Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ olgAndroid.pending_version }} {{ olgAndroid.pending_version_type }}
        </p>
      </div>
    </div>
    <!-- Proline - iOS -->
    <div
      class="
        w-10/12
        flex flex-col
        items-center
        bg-white
        rounded-lg
        border
        shadow-md
        md:flex-row md:max-w-2xl
        hover:bg-gray-100
      "
    >
      <img
        class="
          rounded-t-lg
          md:h-auto
          scale-[0.35]
          md:w-40 md:rounded-none md:rounded-l-lg
        "
        src="../../public/assets/PROLINE-icon.png"
        alt=""
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h6 class="mb-2 font-bold tracking-tight text-orange-600">
          {{ prolineiOS.name }}
        </h6>
        <p class="mb-2 font-bold text-gray-700 ">
          Published Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ prolineiOS.current_version }} {{ prolineiOS.current_version_type }}
        </p>
        <br />
        <p class="mb-2 font-bold text-gray-700 ">
          Pending Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ prolineiOS.pending_version }} {{ prolineiOS.pending_version_type }}
        </p>
      </div>
    </div>
    <!-- Proline Plus - Android -->
    <div
      class="
        w-10/12
        flex flex-col
        items-center
        bg-white
        rounded-lg
        border
        shadow-md
        md:flex-row md:max-w-2xl
        hover:bg-gray-100
      "
    >
      <img
        class="
          rounded-t-lg
          md:h-auto
          scale-[0.35]
          md:w-40 md:rounded-none md:rounded-l-lg
        "
        src="../../public/assets/PROLINE-PLUS-icon.png"
        alt=""
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h6 class="mb-2 font-bold tracking-tight text-orange-600">
          {{ plusAndroid.name }}
        </h6>
        <p class="mb-2 font-bold text-gray-700 ">
          Published Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ plusAndroid.current_version }}
          {{ plusAndroid.current_version_type }}
        </p>
        <br />
        <p class="mb-2 font-bold text-gray-700 ">
          Pending Version:
        </p>
        <p class="mb-2 font-normal text-gray-700 ">
          {{ plusAndroid.pending_version }}
          {{ plusAndroid.pending_version_type }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

//const url_base = "https://web-app-dashboard-test-cc.azurewebsites.net";
const url_base = ".";
export default {
  name: "VersionDashboard",
  props: {
    msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      olgiOS: {
        current_version: "Loading",
        current_version_type: "Loading",
        pending_version: "Loading",
        pending_version_type: "Loading",
      },
      olgAndroid: {
        current_version: "Loading",
        current_version_type: "Loading",
        pending_version: "Loading",
        pending_version_type: "Loading",
      },
      prolineiOS: {
        current_version: "Loading",
        current_version_type: "Loading",
        pending_version: "Loading",
        pending_version_type: "Loading",
      },
      plusAndroid: {
        current_version: "Loading",
        current_version_type: "Loading",
        pending_version: "Loading",
        pending_version_type: "Loading",
      },
    };
  },
  async mounted() {
    try {
      let vm = this;
      await axios.all([
        axios
          .get(url_base + "/appleVersions")
          .then((res) => {
            vm.olgiOS.current_version =
              "current_version" in res.data.lottery
                ? res.data.lottery.current_version
                : "Not found";
            vm.olgiOS.current_version_type =
              "current_version_type" in res.data.lottery
                ? res.data.lottery.current_version_type
                : "";
            vm.olgiOS.pending_version =
              "pending_version" in res.data.lottery
                ? res.data.lottery.pending_version
                : "Not found";
            vm.olgiOS.pending_version_type =
              "pending_version_type" in res.data.lottery
                ? res.data.lottery.pending_version_type
                : "";
            vm.olgiOS.name =
              "name" in res.data.lottery ? res.data.lottery.name : "Not found";

            vm.prolineiOS.current_version =
              "current_version" in res.data.sports
                ? res.data.sports.current_version
                : "Not found";
            vm.prolineiOS.current_version_type =
              "current_version_type" in res.data.sports
                ? res.data.sports.current_version_type
                : "";
            vm.prolineiOS.pending_version =
              "pending_version" in res.data.sports
                ? res.data.sports.pending_version
                : "Not found";
            vm.prolineiOS.pending_version_type =
              "pending_version_type" in res.data.sports
                ? res.data.sports.pending_version_type
                : "";
            vm.prolineiOS.name =
              "name" in res.data.sports ? res.data.sports.name : "Not found";
          })
          .catch((err) => {
            console.log(err);
          }),
        axios
          .get(url_base + "/androidVersions")
          .then((res) => {
            vm.olgAndroid.current_version =
              "current_version" in res.data.lottery
                ? res.data.lottery.current_version
                : "Not found";
            vm.olgAndroid.current_version_type =
              vm.olgAndroid.current_version == "Not found"
                ? ""
                : "Available on Google Play";
            vm.olgAndroid.pending_version =
              "pending_version" in res.data.lottery
                ? res.data.lottery.pending_version
                : "Not found";
            vm.olgAndroid.pending_version_type =
              vm.olgAndroid.pending_version == "Not found"
                ? ""
                : "Ready to publish";
            vm.olgAndroid.name =
              "name" in res.data.lottery ? res.data.lottery.name : "Not found";

            
            vm.plusAndroid.current_version =
              "current_version" in res.data.plus
                ? res.data.plus.current_version
                : "Not found";
            vm.plusAndroid.current_version_type =
              vm.plusAndroid.current_version == "Not found"
                ? ""
                : "Available on Google Play";
            vm.plusAndroid.pending_version =
              "pending_version" in res.data.plus
                ? res.data.plus.pending_version
                : "Not found";
            vm.plusAndroid.pending_version_type =
              vm.plusAndroid.pending_version == "Not found"
                ? ""
                : "Ready to publish";
            vm.plusAndroid.name =
              "name" in res.data.plus ? res.data.plus.name : "Not found";
          })
          .catch((err) => {
            console.log(err);
          }),
      ]);
    } catch (err) {
      this.tempVar = err;
      console.log(err);
    }
  },
};
</script>
<style scoped>
.google-play-store-title {
  display: inline-block;
  margin-top: 10%;
}
.apple-app-store-title {
  display: inline-block;
  margin-top: 10%;
}
.google-play-store-img {
  padding: 10px 10px 10px 10px;
  float: left;
  width: auto;
  max-height: 80px;
  height: 80px;
}

.apple-app-store-img {
  padding: 10px 10px 10px 10px;
  float: left;
  width: auto;
  max-height: 60px;
  height: 60px;
}
</style>
